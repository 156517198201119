import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import { fetchExistingUserFailure, fetchExistingUserSuccess } from "../actions";
import { checkUserApi } from "../../API/API";

function* checkUserSaga(params) {
  console.log("PARAMS IN SAGA:", params);
  try {
    const response = yield call(() => checkUserApi(params)); // API Backend
    console.log(response.data.msg);
    if (response.data.msg === "validated") {
      yield put(fetchExistingUserSuccess(response));
    } else {
      yield put(fetchExistingUserFailure(response.data.error));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchExistingUserFailure(error));
  }
}

export function* watchcheckUserSaga() {
  yield takeEvery(types.CHECK_EXISTING_USER_INIT, checkUserSaga);
}
