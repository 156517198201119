import React, { useState } from "react";
import "./TableCell.css";
import resumeIcon from "../../../assets/icons/documents icon .svg";
import { AiFillStar } from "react-icons/ai";
import { useEffect } from "react";
import {
  AuthenticationProviderName,
  getDateTimeFormat,
} from "./customTableFunction";
import { impersonate } from "../../../pages/Auth/Impersonation";
import emailIcon from "../../../assets/icons/carbon_email.svg";
import phoneIcon from "../../../assets/icons/akar-icons_phone.svg";
import companies from "../../../assets/icons/companies.svg";
import team_members from "../../../assets/icons/team_members.svg";

import locationIcon from "../../../assets/icons/akar-icons_location.svg";
import citizenShipIcon from "../../../assets/icons/bi_person-check.svg";
import chaticon from "../../../assets/icons/cil_chat-bubble.svg";
import chaticonGrey from "../../../assets/icons/cil_chat-bubble-Grey.svg";
import moment from "moment";
import { Badge, Tooltip } from "antd";

import { useHistory } from "react-router-dom";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";
import PivateLogo from "./PivateLogo";

const TableCell = ({ data, updateData,columnsName, user }) => {
  const [handlingBrokenImg, sethandlingBrokenImg] = useState(false);

  const history = useHistory();

  let cellContent = "";
  let columns = "";
  columns = ["Employer", "Candidate"].includes(columnsName)
    ? "CommonUserCell"
    : ["EmployerStatus", "CandidateStatus"].includes(columnsName)
    ? "CommonStatusCell"
    : columnsName;

  const Impersonate = ({ className, children }) => (
    <div
      className={className}
      onClick={(e) => {
        impersonate(data?.UserID, user);
      }}
    >
      {children}
    </div>
  );

  useEffect(() => {}, [data]);
  switch (columns) {
    case "CommonUserCell":
      cellContent = data ? (
        <>
          <Impersonate className="UserCell">
            <div className="UserPicName">
              {!handlingBrokenImg && data?.PictureUrl !== "NA" ? (
                <img
                  src={process.env.REACT_APP_S3_URL+ "/" + data?.PictureUrl}
                  alt=""
                  onError={() => sethandlingBrokenImg(true)}
                />
              ) : (
                <span className="UserChar">
                  {data?.Name.slice(0, 1).toUpperCase()}
                </span>
              )}
              <span className="UserFullName">
                <Tooltip title={data?.Name}>{data?.Name}</Tooltip>
              </span>
              {columnsName === "Candidate" && (
                <>
                  <AiFillStar className="starIcon" />
                  <span className="Rating">
                    {data?.Rating === "NA" ? 3.8 : data?.Rating}
                  </span>
                  <span className="Review">
                    ({data?.Review !== "NA" ? data?.Review : 0})
                  </span>
                </>
              )}
              {user === "Candidate" && (
                <div className="LastLoginInfo">
                  <p
                    className={`UserNonActive ${false ? "UserIsActive" : ""}`}
                  ></p>{" "}
                  <span className="LastLoginTime">
                    {data?.LastLoginDate !== "NA"
                      ? moment(data?.LastLoginDate).fromNow()
                      : data?.LastLoginDate}
                  </span>
                </div>
              )}
              {data?.isAbs && !data?.isAdmin ? (
              <img
                style={{
                  width: "33px",
                  height: "16px",
                  top: "415px",
                  left: "436px",
                  borderRadius: "2px 0px 0px 0px",
                }}
                src={team_members}
                alt="team members"
                onClick={(e) => e.stopPropagation()}
              />
            ) : (data?.isAdmin && !data?.isAbs) ||
              (data?.isAbs && data?.isAdmin) ? (
              <img
                style={{
                  width: "78px",
                  height: "16px",
                  borderRadius: "2px 0px 0px 0px",
                  color: "#4E4E4E",
                }}
                src={companies}
                alt="companies"
                onClick={(e) => e.stopPropagation()}
              />
            ) : null}
            </div>

            <div className="UserOtherInfo">
              <div className="UserOtherInfoInside">
                <img src={emailIcon} alt="" />
                <span>{data?.EmailAddress}</span>
              </div>
              <div className="UserOtherInfoInside">
                <img src={phoneIcon} alt="" />
                <span>
                  {data?.country_calling_codes !== "NA" &&
                    data?.country_calling_codes}{" "}
                  {data?.MobilePhone}
                </span>
              </div>
            </div>
            {columnsName === "Candidate" && (
              <>
                <div className="UserOtherInfo">
                  <div className="UserOtherInfoInside">
                    <img src={locationIcon} alt="" />
                    <span>{data?.Location}</span>
                  </div>
                  <div className="UserOtherInfoInside">
                    <img src={citizenShipIcon} alt="" />
                    <span>{data?.VisaStatusName}</span>
                  </div>
                </div>
                {data?.ResumeFileName != "NA" ? (
                  <a
                    href={process.env.REACT_APP_S3_URL + "/" + data?.ResumeFileName}
                    className="Resume"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img src={resumeIcon} alt="" />
                    <span>Resume</span>
                  </a>
                ) : null}
              </>
            )}
          </Impersonate>
        </>
      ) : null;
      break;

    case "Company":
      cellContent = data ? (
        <Impersonate className="CompanyCell">{data?.CompanyName}</Impersonate>
      ) : null;
      break;

    case "Info":
      cellContent = data ? (
        <Impersonate className="InfoCell">
          <span>
            AP{" "}
            <span className="infoBold">
              {AuthenticationProviderName[data?.AP !== "NA" ? data?.AP : 0]}
            </span>
          </span>
          <span>
            Source <span className="infoBold">{data?.Source}</span>
          </span>
          <span>
            Campaign <span className="infoBold">{data?.Campaign}</span>
          </span>
          <span>Created On {getDateTimeFormat(data?.Created)}</span>
        </Impersonate>
      ) : null;
      break;

    case "Prof":
      cellContent = data ? (
        <Impersonate className="ProfCell">{data?.ProfessionCode}</Impersonate>
      ) : null;
      break;

    case "CommonStatusCell":
      const Status = data ? data?.Status : null;
      cellContent = data ? (
        <Impersonate className="StatusCell">
          <div className="Status">
            <span className={"Status" + Status}>{Status}</span>
          </div>
          <div>
            {["Active", "Inactive", "Pending"].includes(Status) &&
            columnsName === "EmployerStatus" ? (
              <>
                <div className="CreadtedTime">
                  {" "}
                  Created on {getDateTimeFormat(data?.Created)}{" "}
                </div>
                <div className="CreadtedTime">
                  {" "}
                  Last Login on {getDateTimeFormat(data?.LastLoginDate)}{" "}
                </div>
              </>
            ) : (
              <div className="CreadtedTime">
                {" "}
                Last Login on {getDateTimeFormat(data?.LastLoginDate)}{" "}
              </div>
            )}
          </div>
        </Impersonate>
      ) : null;
      break;

    case "CandidateMessage":
      // const Status = data ? data?.Status : null;
      cellContent = (
        <div className={`messaging-icon-cell ${data?.Status == "Deactivated" ? "Talent-conversationExists" : null}`}>
          <div
            className={`messaging-icon ${!data?.adminUnread ? "messaging-icon-cell-grey" : null}`}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/messaging/${data?.CandidateId}`);
            }}
          >
            <Badge color="#DB2828" dot={data?.adminUnread} size={10} offset={[8, -9]}>
              <img src={data?.adminUnread ? chaticon : chaticonGrey} alt="chatIcon" />
            </Badge>
          </div>
        </div>
      );
      // cellContent = data ? <Impersonate></Impersonate> : null;
      break;

    case "Private":
      cellContent = (<PivateLogo updateData={updateData} data={data}/>)
      break;

    default:
      break;
  }

  return <>{data ? cellContent : null}</>;
};

export default TableCell;
