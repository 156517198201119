import { types } from "../action-types";

// import initialState from './appReducer';
const initialState = {
  loading: false,
  user: {
    userExist: null,
  },
  error: {},
};

const chekckUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK_EXISTING_USER_INIT:
      return {
        ...state,
        loading: true,
      };
    case types.CHECK_EXISTING_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, userExist: action.payload.data },
        error: {},
      };
    case types.CHECK_EXISTING_USER_FAILURE:
      return {
        ...state,
        loading: false,
        user: { ...state.user, userExist: action.error },
        error: action.error,
      };
      default:
      return state;
      
};
};

export default chekckUserReducer;
